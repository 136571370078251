.registerContainer {
    --background: none;
    background-color: #111111;
    background-image: url(./images/login.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.registerBox {
    position: relative;
    top: 20%;
    background-color: white;
    border-radius: 10.9538px;
    width: 30%;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 10px;
    font-family: 'Archivo';
    color: #0d0d0d;
}
.registerTitle {
    position: relative;
    top: 10%;
    color: white;
    text-align: center;
    font-family: 'Archivo';
    font-size: 200%;
}

.registerButtons {
    position: relative;
    background: linear-gradient(269.69deg, #9c48c4 -2.63%, #6b5fff 104.5%);
    box-shadow: 0px 4.82353px 4.82353px rgba(0, 0, 0, 0.25);
    border-radius: 25.3235px;
    padding: 10px;
    width: 20%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    font-family: 'Archivo';
}

.registerButtonContainer {
    top: 24%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
}

.loginButtonContainer2 {
    top: 34%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
}
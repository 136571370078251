.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itemCard {
    order: 1;
    width: 100%;
}

.itemImg {
    // order: 5;
    // object-fit: cover;
    width: 45%;
    // height: 10em;
    @media only screen and (max-width: 600px) {
        width: 90%;
    }
}

.ImgGrid {
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
}

.iconButton {
    --border-radius: 0;
    --color: #ffffff;
    text-align: center;
}

.itemDescription {
    background-color: #ffffff;
    border-radius: 3em;
    text-align: left;
    padding: 1em;
    opacity: 70%;
}
